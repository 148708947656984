import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

const preview = ({ article }) => (
  <div className="post-preview">
    <Link to={`/blog/${article.slug}`}>
      <h2 className="post-title">{article.title}</h2>
      <p
        className="post-subtitle"
        dangerouslySetInnerHTML={{
          __html: article.description.description,
        }}
      />
    </Link>
    <p className="post-meta">{article.publishDate}</p>
    <hr />
  </div>
)

preview.propTypes = {
  article: PropTypes.object.isRequired,
}

export default preview
