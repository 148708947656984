import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/layout'
import Header from '../components/header'
import ArticlePreview from '../components/article-preview'
import backgroundImage from '../scss/img/404.jpg'

class NotFound extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const posts = get(this, 'props.data.allContentfulBlogPost.edges')
    const [author] = get(this, 'props.data.allContentfulPerson.edges')

    return (
      <Layout author={author.node}>
        <Helmet
          title={siteTitle}
          meta={[{ name: 'description', content: 'Content not found page.' }]}
        >
          <html lang="en" />
        </Helmet>
        <Header author={author.node} image={backgroundImage}>
          <div className="post-heading">
            <h1>Oops!</h1>
            <h2 className="subheading">
              Sorry that page wasn`&apos;t found. Try one of the articles below
              instead.
            </h2>
          </div>
        </Header>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-10 mx-auto">
              {posts.map(({ node }, key) => {
                return <ArticlePreview key={key} article={node} />
              })}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default NotFound

export const pageQuery = graphql`
  query NotFoundQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulPerson(
      filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }
    ) {
      edges {
        node {
          name
          linkedIn
          twitter
          github
        }
      }
    }
  }
`
